import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { transformRawSwaps } from "../../data/mockData";
import { tokens } from "../../theme";

const Swaps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {field: "id",  headerName:"ID", flex:0.2},
    {field: "username", headerName: "NAME", flex: 1},
    {field: "dispatched", headerName: "DISPATCHED ID", flex: 1,},
    {field: "dispatched_charge", headerName: "DISPATCH CHARGE", flex: 0.7},
    {field: "deposited", headerName: "DEPOSITED ID", flex: 0.7},
    {field: "deposited_charge", headerName: "DEPOSITED CHARGE", flex: 0.5},
    {field: "status", headerName: "SWAP STATUS", flex: 1},
    {field: "cabinetId", headerName: "CABINET ID", flex: 1},    
    {field: "time", headerName: "TIME", flex: 1},
  ]


  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Swaps" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={transformRawSwaps()} columns={columns} />
      </Box>
    </Box>
  );
};

export default Swaps;
